var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "menu-layout"
  }, [_c('the-result-modal', {
    ref: "$resultModal",
    attrs: {
      "name": "menu-layout__modal"
    },
    on: {
      "on-submit": _vm.closeModal
    }
  }), _vm._v(" "), _c('the-header', _vm._g({
    staticClass: "menu-layout__header",
    attrs: {
      "hide-catalog-aside": _vm.hideCatalogAside,
      "hide-search": _vm.hideSearch,
      "navigation-items": _vm.navigation,
      "unread-messages-count": _vm.unreadMessagesCount,
      "unread-notifications-count": _vm.unreadNotificationsCount,
      "logo-link": _vm.logoLink
    },
    on: {
      "on-notifications": function ($event) {
        return _vm.$emit('on-notifications');
      }
    },
    scopedSlots: _vm._u([{
      key: "billet",
      fn: function () {
        return [_vm._t("billet")];
      },
      proxy: true
    }], null, true)
  }, _vm.$listeners)), _vm._v(" "), !_vm.hideCatalogAside ? _c('app-aside', {
    staticClass: "menu-layout__aside",
    attrs: {
      "is-open": _vm.openedAside === 'catalog',
      "placement": "left"
    },
    on: {
      "on-close": _vm.closeAside
    }
  }, [_c('div', {
    staticClass: "menu-layout__aside-content"
  }, [_vm._t("aside-catalog")], 2)]) : _vm._e(), _vm._v(" "), _c('app-aside', {
    staticClass: "menu-layout__aside",
    attrs: {
      "is-open": _vm.openedAside === 'profile',
      "placement": "right"
    },
    on: {
      "on-close": _vm.closeAside
    }
  }, [_c('div', {
    staticClass: "menu-layout__aside-content"
  }, [_vm._t("aside-profile")], 2)]), _vm._v(" "), _c('div', {
    staticClass: "menu-layout__content"
  }, [_c('div', {
    staticClass: "menu-layout__catalog"
  }, [_vm._t("catalog")], 2), _vm._v(" "), _c('div', {
    staticClass: "menu-layout__nuxt-content"
  }, [_c('client-only', [_vm._t("default")], 2)], 1)]), _vm._v(" "), _c('the-footer', {
    staticClass: "menu-layout__footer"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }