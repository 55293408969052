






































import {
    defineComponent,
    onMounted,
    ref,
    useContext,
    useRouter
} from '@nuxtjs/composition-api'

import CrossIcon from '@/assets/img/close.svg'
import MagnifierIcon from '@/assets/img/magnifier.svg'

export default defineComponent({
    components: {
        CrossIcon,
        MagnifierIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Найти в каталоге'
        }
    },
    setup: (props, { emit }) => {
        const router = useRouter()
        const { route } = useContext()
        const isFocused = ref(false)
        const onFocus = () => {
            isFocused.value = true
        }
        const onBlur = () => {
            isFocused.value = false
        }

        const onClear = (needClearSearch?: boolean) => {
            emit('on-clear', props.value)
            emit('input', '')

            const query = { ...route.value?.query }

            if (needClearSearch && Object.keys(route.value?.query).includes('search')) {
                Object.keys(route.value?.query).forEach((key) => {
                    if (key === 'search') {
                        delete query.search

                        router.push({
                            query: {
                                ...query
                            }
                        })
                    }
                })
            }

            return undefined
        }

        onMounted(() => {
            if (route.value.query.search && route.value.name?.includes('showcases-categories')) {
                emit('input', route.value.query.search)
            }
        })

        const onSearch = () => {
            if (!props.value.trim()) {
                return undefined
            }

            emit('on-search', props.value)
        }

        return {
            isFocused,
            onClear,
            onSearch,
            onFocus,
            onBlur
        }
    }
})
