import { render, staticRenderFns } from "./-MenuLayout.vue?vue&type=template&id=394d90f0&scoped=true&"
import script from "./-MenuLayout.vue?vue&type=script&lang=ts&"
export * from "./-MenuLayout.vue?vue&type=script&lang=ts&"
import style0 from "./-MenuLayout.vue?vue&type=style&index=0&id=394d90f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394d90f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheResultModal: require('/var/www/app/src/components/ui/TheResultModal.vue').default,TheHeader: require('/var/www/app/src/components/pages/TheHeader/index.vue').default,AppAside: require('/var/www/app/src/components/ui/AppAside.vue').default,TheFooter: require('/var/www/app/src/components/ui/TheFooter.vue').default})
