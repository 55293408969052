export const AppAside = () => import('../../src/components/ui/AppAside.vue' /* webpackChunkName: "components/app-aside" */).then(c => wrapFunctional(c.default || c))
export const AppAvatar = () => import('../../src/components/ui/AppAvatar.vue' /* webpackChunkName: "components/app-avatar" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../src/components/ui/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppCalendar = () => import('../../src/components/ui/AppCalendar.vue' /* webpackChunkName: "components/app-calendar" */).then(c => wrapFunctional(c.default || c))
export const AppCaptchaComponent = () => import('../../src/components/ui/AppCaptchaComponent.vue' /* webpackChunkName: "components/app-captcha-component" */).then(c => wrapFunctional(c.default || c))
export const AppCarousel = () => import('../../src/components/ui/AppCarousel.vue' /* webpackChunkName: "components/app-carousel" */).then(c => wrapFunctional(c.default || c))
export const AppCategoryFilter = () => import('../../src/components/ui/AppCategoryFilter.vue' /* webpackChunkName: "components/app-category-filter" */).then(c => wrapFunctional(c.default || c))
export const AppCategoryFilterSkeleton = () => import('../../src/components/ui/AppCategoryFilterSkeleton.vue' /* webpackChunkName: "components/app-category-filter-skeleton" */).then(c => wrapFunctional(c.default || c))
export const AppChatModal = () => import('../../src/components/ui/AppChatModal.vue' /* webpackChunkName: "components/app-chat-modal" */).then(c => wrapFunctional(c.default || c))
export const AppCheckbox = () => import('../../src/components/ui/AppCheckbox.vue' /* webpackChunkName: "components/app-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AppChips = () => import('../../src/components/ui/AppChips.vue' /* webpackChunkName: "components/app-chips" */).then(c => wrapFunctional(c.default || c))
export const AppCkEditor = () => import('../../src/components/ui/AppCkEditor.vue' /* webpackChunkName: "components/app-ck-editor" */).then(c => wrapFunctional(c.default || c))
export const AppCodeEditor = () => import('../../src/components/ui/AppCodeEditor.vue' /* webpackChunkName: "components/app-code-editor" */).then(c => wrapFunctional(c.default || c))
export const AppCopy = () => import('../../src/components/ui/AppCopy.vue' /* webpackChunkName: "components/app-copy" */).then(c => wrapFunctional(c.default || c))
export const AppDatepicker = () => import('../../src/components/ui/AppDatepicker.vue' /* webpackChunkName: "components/app-datepicker" */).then(c => wrapFunctional(c.default || c))
export const AppDivider = () => import('../../src/components/ui/AppDivider.vue' /* webpackChunkName: "components/app-divider" */).then(c => wrapFunctional(c.default || c))
export const AppDrawer = () => import('../../src/components/ui/AppDrawer.vue' /* webpackChunkName: "components/app-drawer" */).then(c => wrapFunctional(c.default || c))
export const AppDropdown = () => import('../../src/components/ui/AppDropdown.vue' /* webpackChunkName: "components/app-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppDropdownFilters = () => import('../../src/components/ui/AppDropdownFilters.vue' /* webpackChunkName: "components/app-dropdown-filters" */).then(c => wrapFunctional(c.default || c))
export const AppDropdownMenu = () => import('../../src/components/ui/AppDropdownMenu.vue' /* webpackChunkName: "components/app-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const AppEditor = () => import('../../src/components/ui/AppEditor.vue' /* webpackChunkName: "components/app-editor" */).then(c => wrapFunctional(c.default || c))
export const AppFastFill = () => import('../../src/components/ui/AppFastFill.vue' /* webpackChunkName: "components/app-fast-fill" */).then(c => wrapFunctional(c.default || c))
export const AppFilters = () => import('../../src/components/ui/AppFilters.vue' /* webpackChunkName: "components/app-filters" */).then(c => wrapFunctional(c.default || c))
export const AppForm = () => import('../../src/components/ui/AppForm.vue' /* webpackChunkName: "components/app-form" */).then(c => wrapFunctional(c.default || c))
export const AppGridTable = () => import('../../src/components/ui/AppGridTable.vue' /* webpackChunkName: "components/app-grid-table" */).then(c => wrapFunctional(c.default || c))
export const AppHeightTransition = () => import('../../src/components/ui/AppHeightTransition.vue' /* webpackChunkName: "components/app-height-transition" */).then(c => wrapFunctional(c.default || c))
export const AppHelperMessage = () => import('../../src/components/ui/AppHelperMessage.vue' /* webpackChunkName: "components/app-helper-message" */).then(c => wrapFunctional(c.default || c))
export const AppHint = () => import('../../src/components/ui/AppHint.vue' /* webpackChunkName: "components/app-hint" */).then(c => wrapFunctional(c.default || c))
export const AppInfoTable = () => import('../../src/components/ui/AppInfoTable.vue' /* webpackChunkName: "components/app-info-table" */).then(c => wrapFunctional(c.default || c))
export const AppInputFile = () => import('../../src/components/ui/AppInputFile.vue' /* webpackChunkName: "components/app-input-file" */).then(c => wrapFunctional(c.default || c))
export const AppInputLabel = () => import('../../src/components/ui/AppInputLabel.vue' /* webpackChunkName: "components/app-input-label" */).then(c => wrapFunctional(c.default || c))
export const AppList = () => import('../../src/components/ui/AppList.vue' /* webpackChunkName: "components/app-list" */).then(c => wrapFunctional(c.default || c))
export const AppMagnifier = () => import('../../src/components/ui/AppMagnifier.vue' /* webpackChunkName: "components/app-magnifier" */).then(c => wrapFunctional(c.default || c))
export const AppMenuControlBlock = () => import('../../src/components/ui/AppMenuControlBlock.vue' /* webpackChunkName: "components/app-menu-control-block" */).then(c => wrapFunctional(c.default || c))
export const AppMenuControlEditBlock = () => import('../../src/components/ui/AppMenuControlEditBlock.vue' /* webpackChunkName: "components/app-menu-control-edit-block" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../src/components/ui/AppModal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppNumCheckbox = () => import('../../src/components/ui/AppNumCheckbox.vue' /* webpackChunkName: "components/app-num-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionnaireFields = () => import('../../src/components/ui/AppQuestionnaireFields.vue' /* webpackChunkName: "components/app-questionnaire-fields" */).then(c => wrapFunctional(c.default || c))
export const AppRadio = () => import('../../src/components/ui/AppRadio.vue' /* webpackChunkName: "components/app-radio" */).then(c => wrapFunctional(c.default || c))
export const AppSegments = () => import('../../src/components/ui/AppSegments.vue' /* webpackChunkName: "components/app-segments" */).then(c => wrapFunctional(c.default || c))
export const AppSelectKeyValue = () => import('../../src/components/ui/AppSelectKeyValue.vue' /* webpackChunkName: "components/app-select-key-value" */).then(c => wrapFunctional(c.default || c))
export const AppSpinner = () => import('../../src/components/ui/AppSpinner.vue' /* webpackChunkName: "components/app-spinner" */).then(c => wrapFunctional(c.default || c))
export const AppSpoiler = () => import('../../src/components/ui/AppSpoiler.vue' /* webpackChunkName: "components/app-spoiler" */).then(c => wrapFunctional(c.default || c))
export const AppStatus = () => import('../../src/components/ui/AppStatus.vue' /* webpackChunkName: "components/app-status" */).then(c => wrapFunctional(c.default || c))
export const AppSteps = () => import('../../src/components/ui/AppSteps.vue' /* webpackChunkName: "components/app-steps" */).then(c => wrapFunctional(c.default || c))
export const AppStory = () => import('../../src/components/ui/AppStory.vue' /* webpackChunkName: "components/app-story" */).then(c => wrapFunctional(c.default || c))
export const AppStoryFrame = () => import('../../src/components/ui/AppStoryFrame.vue' /* webpackChunkName: "components/app-story-frame" */).then(c => wrapFunctional(c.default || c))
export const AppStoryModal = () => import('../../src/components/ui/AppStoryModal.vue' /* webpackChunkName: "components/app-story-modal" */).then(c => wrapFunctional(c.default || c))
export const AppSubmitionForm = () => import('../../src/components/ui/AppSubmitionForm.vue' /* webpackChunkName: "components/app-submition-form" */).then(c => wrapFunctional(c.default || c))
export const AppSwitch = () => import('../../src/components/ui/AppSwitch.vue' /* webpackChunkName: "components/app-switch" */).then(c => wrapFunctional(c.default || c))
export const AppSwitchMultiple = () => import('../../src/components/ui/AppSwitchMultiple.vue' /* webpackChunkName: "components/app-switch-multiple" */).then(c => wrapFunctional(c.default || c))
export const AppTable = () => import('../../src/components/ui/AppTable.vue' /* webpackChunkName: "components/app-table" */).then(c => wrapFunctional(c.default || c))
export const AppTabs = () => import('../../src/components/ui/AppTabs.vue' /* webpackChunkName: "components/app-tabs" */).then(c => wrapFunctional(c.default || c))
export const AppTextStatus = () => import('../../src/components/ui/AppTextStatus.vue' /* webpackChunkName: "components/app-text-status" */).then(c => wrapFunctional(c.default || c))
export const AppTextView = () => import('../../src/components/ui/AppTextView.vue' /* webpackChunkName: "components/app-text-view" */).then(c => wrapFunctional(c.default || c))
export const AppTextarea = () => import('../../src/components/ui/AppTextarea.vue' /* webpackChunkName: "components/app-textarea" */).then(c => wrapFunctional(c.default || c))
export const AppTimePicker = () => import('../../src/components/ui/AppTimePicker.vue' /* webpackChunkName: "components/app-time-picker" */).then(c => wrapFunctional(c.default || c))
export const AppTooltip = () => import('../../src/components/ui/AppTooltip.vue' /* webpackChunkName: "components/app-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AppVideo = () => import('../../src/components/ui/AppVideo.vue' /* webpackChunkName: "components/app-video" */).then(c => wrapFunctional(c.default || c))
export const AppWidget = () => import('../../src/components/ui/AppWidget.vue' /* webpackChunkName: "components/app-widget" */).then(c => wrapFunctional(c.default || c))
export const AppWidgetSkeleton = () => import('../../src/components/ui/AppWidgetSkeleton.vue' /* webpackChunkName: "components/app-widget-skeleton" */).then(c => wrapFunctional(c.default || c))
export const BaseAside = () => import('../../src/components/ui/BaseAside.vue' /* webpackChunkName: "components/base-aside" */).then(c => wrapFunctional(c.default || c))
export const BaseBeacon = () => import('../../src/components/ui/BaseBeacon.vue' /* webpackChunkName: "components/base-beacon" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdown = () => import('../../src/components/ui/BaseDropdown.vue' /* webpackChunkName: "components/base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../src/components/ui/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../src/components/ui/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseSpoiler = () => import('../../src/components/ui/BaseSpoiler.vue' /* webpackChunkName: "components/base-spoiler" */).then(c => wrapFunctional(c.default || c))
export const BaseWidget = () => import('../../src/components/ui/BaseWidget.vue' /* webpackChunkName: "components/base-widget" */).then(c => wrapFunctional(c.default || c))
export const SkeletonItem = () => import('../../src/components/ui/SkeletonItem.vue' /* webpackChunkName: "components/skeleton-item" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../src/components/ui/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheGuestHeader = () => import('../../src/components/ui/TheGuestHeader.vue' /* webpackChunkName: "components/the-guest-header" */).then(c => wrapFunctional(c.default || c))
export const TheResultModal = () => import('../../src/components/ui/TheResultModal.vue' /* webpackChunkName: "components/the-result-modal" */).then(c => wrapFunctional(c.default || c))
export const UnsavedChangesModal = () => import('../../src/components/ui/UnsavedChangesModal.vue' /* webpackChunkName: "components/unsaved-changes-modal" */).then(c => wrapFunctional(c.default || c))
export const AppChat = () => import('../../src/components/ui/AppChat/index.vue' /* webpackChunkName: "components/app-chat" */).then(c => wrapFunctional(c.default || c))
export const ColorPicker = () => import('../../src/components/ui/AppColorPicker/ColorPicker.vue' /* webpackChunkName: "components/color-picker" */).then(c => wrapFunctional(c.default || c))
export const ColorPickerItem = () => import('../../src/components/ui/AppColorPicker/ColorPickerItem.vue' /* webpackChunkName: "components/color-picker-item" */).then(c => wrapFunctional(c.default || c))
export const CommentsBody = () => import('../../src/components/ui/AppCommentsBlock/CommentsBody.vue' /* webpackChunkName: "components/comments-body" */).then(c => wrapFunctional(c.default || c))
export const CommentsInput = () => import('../../src/components/ui/AppCommentsBlock/CommentsInput.vue' /* webpackChunkName: "components/comments-input" */).then(c => wrapFunctional(c.default || c))
export const CommentsList = () => import('../../src/components/ui/AppCommentsBlock/CommentsList.vue' /* webpackChunkName: "components/comments-list" */).then(c => wrapFunctional(c.default || c))
export const CommentsNestedList = () => import('../../src/components/ui/AppCommentsBlock/CommentsNestedList.vue' /* webpackChunkName: "components/comments-nested-list" */).then(c => wrapFunctional(c.default || c))
export const AppCommentsBlock = () => import('../../src/components/ui/AppCommentsBlock/index.vue' /* webpackChunkName: "components/app-comments-block" */).then(c => wrapFunctional(c.default || c))
export const AppEmoji = () => import('../../src/components/ui/AppEmoji/index.vue' /* webpackChunkName: "components/app-emoji" */).then(c => wrapFunctional(c.default || c))
export const AppErrorsList = () => import('../../src/components/ui/AppError/AppErrorsList.vue' /* webpackChunkName: "components/app-errors-list" */).then(c => wrapFunctional(c.default || c))
export const AppError = () => import('../../src/components/ui/AppError/index.vue' /* webpackChunkName: "components/app-error" */).then(c => wrapFunctional(c.default || c))
export const AppInputPassword = () => import('../../src/components/ui/AppInput/AppInputPassword.vue' /* webpackChunkName: "components/app-input-password" */).then(c => wrapFunctional(c.default || c))
export const AppInputSearch = () => import('../../src/components/ui/AppInput/AppInputSearch.vue' /* webpackChunkName: "components/app-input-search" */).then(c => wrapFunctional(c.default || c))
export const AppInput = () => import('../../src/components/ui/AppInput/index.vue' /* webpackChunkName: "components/app-input" */).then(c => wrapFunctional(c.default || c))
export const AppListButtons = () => import('../../src/components/ui/AppListButtons/index.vue' /* webpackChunkName: "components/app-list-buttons" */).then(c => wrapFunctional(c.default || c))
export const PinnedPublication = () => import('../../src/components/ui/AppPublications/PinnedPublication.vue' /* webpackChunkName: "components/pinned-publication" */).then(c => wrapFunctional(c.default || c))
export const PinnedPublicationSkeleton = () => import('../../src/components/ui/AppPublications/PinnedPublicationSkeleton.vue' /* webpackChunkName: "components/pinned-publication-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PublicationBanner = () => import('../../src/components/ui/AppPublications/PublicationBanner.vue' /* webpackChunkName: "components/publication-banner" */).then(c => wrapFunctional(c.default || c))
export const PublicationBlock = () => import('../../src/components/ui/AppPublications/PublicationBlock.vue' /* webpackChunkName: "components/publication-block" */).then(c => wrapFunctional(c.default || c))
export const PublicationBlockSkeleton = () => import('../../src/components/ui/AppPublications/PublicationBlockSkeleton.vue' /* webpackChunkName: "components/publication-block-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PublicationCard = () => import('../../src/components/ui/AppPublications/PublicationCard.vue' /* webpackChunkName: "components/publication-card" */).then(c => wrapFunctional(c.default || c))
export const PublicationCarousel = () => import('../../src/components/ui/AppPublications/PublicationCarousel.vue' /* webpackChunkName: "components/publication-carousel" */).then(c => wrapFunctional(c.default || c))
export const PublicationCarouselSkeleton = () => import('../../src/components/ui/AppPublications/PublicationCarouselSkeleton.vue' /* webpackChunkName: "components/publication-carousel-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PublicationContentSkeleton = () => import('../../src/components/ui/AppPublications/PublicationContentSkeleton.vue' /* webpackChunkName: "components/publication-content-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PublicationHeader = () => import('../../src/components/ui/AppPublications/PublicationHeader.vue' /* webpackChunkName: "components/publication-header" */).then(c => wrapFunctional(c.default || c))
export const PublicationHeaderSkeleton = () => import('../../src/components/ui/AppPublications/PublicationHeaderSkeleton.vue' /* webpackChunkName: "components/publication-header-skeleton" */).then(c => wrapFunctional(c.default || c))
export const PublicationLike = () => import('../../src/components/ui/AppPublications/PublicationLike.vue' /* webpackChunkName: "components/publication-like" */).then(c => wrapFunctional(c.default || c))
export const SubscriptionBanner = () => import('../../src/components/ui/AppPublications/SubscriptionBanner.vue' /* webpackChunkName: "components/subscription-banner" */).then(c => wrapFunctional(c.default || c))
export const AppSelectMultiple = () => import('../../src/components/ui/AppSelect/AppSelectMultiple.vue' /* webpackChunkName: "components/app-select-multiple" */).then(c => wrapFunctional(c.default || c))
export const AppSelect = () => import('../../src/components/ui/AppSelect/index.vue' /* webpackChunkName: "components/app-select" */).then(c => wrapFunctional(c.default || c))
export const AppSlider = () => import('../../src/components/ui/AppSlider/index.vue' /* webpackChunkName: "components/app-slider" */).then(c => wrapFunctional(c.default || c))
export const AppFile = () => import('../../src/components/ui/AppUpload/AppFile.vue' /* webpackChunkName: "components/app-file" */).then(c => wrapFunctional(c.default || c))
export const AppUploadArea = () => import('../../src/components/ui/AppUpload/AppUploadArea.vue' /* webpackChunkName: "components/app-upload-area" */).then(c => wrapFunctional(c.default || c))
export const AppUpload = () => import('../../src/components/ui/AppUpload/index.vue' /* webpackChunkName: "components/app-upload" */).then(c => wrapFunctional(c.default || c))
export const ResultStep = () => import('../../src/components/ui/ModalSteps/ResultStep.vue' /* webpackChunkName: "components/result-step" */).then(c => wrapFunctional(c.default || c))
export const RestoreFinishStep = () => import('../../src/components/ui/TheAuth/RestoreFinishStep.vue' /* webpackChunkName: "components/restore-finish-step" */).then(c => wrapFunctional(c.default || c))
export const RestoreLoginConfirmationStep = () => import('../../src/components/ui/TheAuth/RestoreLoginConfirmationStep.vue' /* webpackChunkName: "components/restore-login-confirmation-step" */).then(c => wrapFunctional(c.default || c))
export const RestorePasswordConfirmationStep = () => import('../../src/components/ui/TheAuth/RestorePasswordConfirmationStep.vue' /* webpackChunkName: "components/restore-password-confirmation-step" */).then(c => wrapFunctional(c.default || c))
export const RestorePasswordModal = () => import('../../src/components/ui/TheAuth/RestorePasswordModal.vue' /* webpackChunkName: "components/restore-password-modal" */).then(c => wrapFunctional(c.default || c))
export const RestorePasswordStartStep = () => import('../../src/components/ui/TheAuth/RestorePasswordStartStep.vue' /* webpackChunkName: "components/restore-password-start-step" */).then(c => wrapFunctional(c.default || c))
export const SignInModal = () => import('../../src/components/ui/TheAuth/SignInModal.vue' /* webpackChunkName: "components/sign-in-modal" */).then(c => wrapFunctional(c.default || c))
export const SignUpModal = () => import('../../src/components/ui/TheAuth/SignUpModal.vue' /* webpackChunkName: "components/sign-up-modal" */).then(c => wrapFunctional(c.default || c))
export const ThePromoConstructor = () => import('../../src/components/ui/ThePromoConstructor/index.vue' /* webpackChunkName: "components/the-promo-constructor" */).then(c => wrapFunctional(c.default || c))
export const TheQuestionnaire = () => import('../../src/components/ui/TheQuestionnaire/index.vue' /* webpackChunkName: "components/the-questionnaire" */).then(c => wrapFunctional(c.default || c))
export const TheSignUp = () => import('../../src/components/ui/TheSignUp/index.vue' /* webpackChunkName: "components/the-sign-up" */).then(c => wrapFunctional(c.default || c))
export const Block = () => import('../../src/components/ui/ThePromoConstructor/-Block/index.vue' /* webpackChunkName: "components/block" */).then(c => wrapFunctional(c.default || c))
export const ExecutorItem = () => import('../../src/components/customer/ExecutorItem.vue' /* webpackChunkName: "components/executor-item" */).then(c => wrapFunctional(c.default || c))
export const TheMobileProfile = () => import('../../src/components/customer/TheMobileProfile.vue' /* webpackChunkName: "components/the-mobile-profile" */).then(c => wrapFunctional(c.default || c))
export const TheCatalog = () => import('../../src/components/customer/TheCatalog/index.vue' /* webpackChunkName: "components/the-catalog" */).then(c => wrapFunctional(c.default || c))
export const Desktop = () => import('../../src/components/customer/ShowcasesFilter/desktop/index.vue' /* webpackChunkName: "components/desktop" */).then(c => wrapFunctional(c.default || c))
export const Mobile = () => import('../../src/components/customer/ShowcasesFilter/mobile/index.vue' /* webpackChunkName: "components/mobile" */).then(c => wrapFunctional(c.default || c))
export const Iam = () => import('../../src/components/iam/index.vue' /* webpackChunkName: "components/iam" */).then(c => wrapFunctional(c.default || c))
export const ChangeIdentifierModal = () => import('../../src/components/iam/-ChangeIdentifierModal/index.vue' /* webpackChunkName: "components/change-identifier-modal" */).then(c => wrapFunctional(c.default || c))
export const PageAnchor = () => import('../../src/components/pages/PageAnchor.vue' /* webpackChunkName: "components/page-anchor" */).then(c => wrapFunctional(c.default || c))
export const PageContentBlock = () => import('../../src/components/pages/PageContentBlock.vue' /* webpackChunkName: "components/page-content-block" */).then(c => wrapFunctional(c.default || c))
export const PageFloatBlock = () => import('../../src/components/pages/PageFloatBlock.vue' /* webpackChunkName: "components/page-float-block" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../src/components/pages/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageListBlock = () => import('../../src/components/pages/PageListBlock.vue' /* webpackChunkName: "components/page-list-block" */).then(c => wrapFunctional(c.default || c))
export const PageSubtitle = () => import('../../src/components/pages/PageSubtitle.vue' /* webpackChunkName: "components/page-subtitle" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../src/components/pages/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const TheBreadcrumbs = () => import('../../src/components/pages/TheBreadcrumbs/index.vue' /* webpackChunkName: "components/the-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const CatalogItem = () => import('../../src/components/pages/TheCatalog/CatalogItem.vue' /* webpackChunkName: "components/catalog-item" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../src/components/pages/TheHeader/index.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
