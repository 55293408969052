



































import { RawLocation } from 'vue-router'

import {
    PropType,
    defineComponent,
    onMounted,
    ref,
    useContext
} from '@nuxtjs/composition-api'

import { CatalogItem as CatalogItemInterface } from '@/interfaces/catalog.interface'
import { Components as Customer } from '@/types/client.customer'

import CatalogSocials from './-CatalogSocials.vue'
import AppWidgetSkeleton from '@/components/ui/AppWidgetSkeleton.vue'
import CatalogItemSkeleton from './-CatalogItemSkeleton.vue'

export default defineComponent({
    components: {
        CatalogSocials,
        AppWidgetSkeleton,
        CatalogItemSkeleton
    },
    props: {
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        },
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        },
        hideProfileInfo: {
            type: Boolean,
            default: false
        },
        aside: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const { $apiCustomer } = useContext()

        const widget = ref<Customer.Schemas.WidgetElement | null>(null)
        const userSegment = ref<Customer.Schemas.UserSegment | null>(null)
        const DEFAULT_SEGMENT = 'beginner-all-achieve'

        const fetchWidget = async () => {
            const {
                data: [ firstWidgetData ]
            } = await $apiCustomer.widgetsGetPersonalizedWidgets({
                segmentAlias: userSegment.value?.segmentAlias as string || DEFAULT_SEGMENT,
                widgetPlaces: [ 'under-menu' ]
            })

            widget.value = firstWidgetData
        }

        const fetchUserSegment = async () => {
            const { data: userSegmentResponse } = await $apiCustomer.segmentsGetUserSegment()

            userSegment.value = userSegmentResponse
        }

        onMounted(async () => {
            try {
                await fetchUserSegment()
                await fetchWidget()
            } catch (error) {
                console.error(error)
            }
        })

        return {
            widget
        }
    }
})
