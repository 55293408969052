var render = function () {
  var _vm$currentDevice, _vm$currentDevice2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "mouseleave": function ($event) {
        return _vm.onTrigger('OnLeave');
      },
      "touchmove": function ($event) {
        return _vm.onTrigger('OnScroll');
      },
      "wheel": function ($event) {
        return _vm.onTrigger('OnScroll');
      }
    }
  }, [_vm.popups.length ? _c('popup', {
    ref: "$popup"
  }) : _vm._e(), _vm._v(" "), _c('menu-layout', {
    class: ['customer'],
    attrs: {
      "navigation": _vm.navigation,
      "unread-notifications-count": _vm.unreadNotificationsCount
    },
    on: {
      "on-notifications": function ($event) {
        return _vm.$router.push('/notifications');
      }
    },
    scopedSlots: _vm._u([((_vm$currentDevice = _vm.currentDevice) === null || _vm$currentDevice === void 0 ? void 0 : _vm$currentDevice.alias) === 'desktop' ? {
      key: "catalog",
      fn: function () {
        return [_c('the-catalog', {
          staticClass: "customer__catalog",
          attrs: {
            "catalog-items": _vm.catalog
          }
        })];
      },
      proxy: true
    } : null, ((_vm$currentDevice2 = _vm.currentDevice) === null || _vm$currentDevice2 === void 0 ? void 0 : _vm$currentDevice2.alias) === 'mobile' ? {
      key: "aside-catalog",
      fn: function () {
        return [_c('the-catalog', {
          staticClass: "customer__aside-catalog",
          attrs: {
            "hide-profile-info": "",
            "aside": "",
            "catalog-items": _vm.catalog
          }
        })];
      },
      proxy: true
    } : null, {
      key: "aside-profile",
      fn: function () {
        return [_c('the-mobile-profile', {
          staticClass: "customer__aside-profile",
          attrs: {
            "catalog-items": _vm.catalog,
            "navigation": _vm.mobileNavigation
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('nuxt')];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }