

























































import { RawLocation, Route } from 'vue-router'

import { defineComponent, PropType } from '@nuxtjs/composition-api'

import { openAside, closeAside, openedAside } from '@/composables/asides'
import { $resultModal, closeModal } from '@/composables/result-modal'

import { NavigationItem } from '@/interfaces/navigation.interface'

export default defineComponent({
    props: {
        navigation: {
            type: Array as PropType<NavigationItem[]>,
            default: () => []
        },
        profileLink: {
            type: [ Object, String ] as PropType<Route | string>,
            default: '/profile'
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        hideCatalogAside: {
            type: Boolean,
            default: false
        },
        unreadMessagesCount: {
            type: Number,
            default: 0
        },
        unreadNotificationsCount: {
            type: Number,
            default: 0
        },
        logoLink: {
            type: [ String, Object ] as PropType<RawLocation>,
            default: '/'
        }
    },
    setup: () => ({
        $resultModal,
        closeModal,
        openAside,
        closeAside,
        openedAside
    })
})
